import * as S from "./styles";

const Top = () => {
  return (
    <S.Container>
      <S.Name>GMVRPW</S.Name>
    </S.Container>
  );
};

export default Top;
